<template>
  <div id="app">
    <div class="main-wrapper" id="mainWrapper">
      <NavbarComponent v-bind="navbarObj"></NavbarComponent>
      <div class="content">
        <router-view v-bind="homeObj" />
      </div>
      <FooterComponent v-bind="footerObj"></FooterComponent>

      <div v-if="showMomentsGallery">
        <div class="moments-gallery-main">
          <div @click="closeMomentsGallery" class="close-btn"></div>

          <MomentsCarouselComponent></MomentsCarouselComponent>

        </div>
      </div>

      <div v-if="showSpeakerVideoPopup">
        <div class="speaker-video-main">
          <div class="speaker-video-container">
            <div @click="ToggleSpeakerVideoPopup" class="close-btn"></div>
            <div class="speaker-video-box">
              <iframe width="100%" height="100" :src="`${videoPopupSpeaker.videoUrl}`" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
              </iframe>

            </div>
          </div>
        </div>
      </div>
      <div v-if="showSpeakerPageVideoPopup">
        <div class="speaker-page-video-main">
          <div class="speaker-page-video-container">
            <div @click="ToggleSpeakerPageVideoPopup" class="close-btn"></div>
            <div class="speaker-page-video-box">
              <iframe width="100%" height="100" :src="`${videoPopupSpeakerPage.videoLink}`" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
              </iframe>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import router from "@/router";
import ContentService from "@/core/services/content.service";
import CoreService from "@/core/services/core.service";
import PostMessageService from "@/core/services/post-message.service";
import NavbarComponent from "@/components/navbar/index.vue";
import HomeComponent from "@/views/home/index.vue";
import FooterComponent from "@/components/footer/index.vue";
import MomentsCarouselComponent from "@/components/momentscarousel/index.vue";
import moment from "moment";
import axios from "axios";
const langtext = require("@/langtexts.json");
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  components: {
    ContentService,
    PostMessageService,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    MomentsCarouselComponent,
    Swiper,
    SwiperSlide,
  },

  created() {
    this.handleEnvLog();
  },
  data() {
    return {
      isDescWidth: false,
      isTabWidth: false,
      isMobWidth: false,
      isXSWidth: false,
      showMobMenu: false,
      siteCurrentLangGe: false,
      langtext,
      showMomentsGallery: false,
      showSpeakerVideoPopup: false,
      showSpeakerPageVideoPopup: false,
      videoPopupSpeaker: null,
      videoPopupSpeakerPage: null,
    };
  },

  methods: {
    openMomentsGallery() {
      this.showMomentsGallery = true;
    },
    closeMomentsGallery() {
      this.showMomentsGallery = false;
    },
    openSpeakerVideoPopup(speaker, event) {
      // console.log("click speaker", speaker);
      if (!speaker.hasVideo) {
        return false;
      }
      this.videoPopupSpeaker = speaker;
      this.showSpeakerVideoPopup = true;
    },
    ToggleSpeakerVideoPopup() {
      this.showSpeakerVideoPopup = !this.showSpeakerVideoPopup;
    },
    closeSpeakerVideoPopup() {
      this.showSpeakerVideoPopup = false;
    },
    openSpeakerPageVideoPopup(speaker, event) {
      // console.log("click speaker page", speaker);
      if (!speaker.hasVideo) {
        return false;
      }
      this.videoPopupSpeakerPage = speaker;
      this.showSpeakerPageVideoPopup = true;
    },
    ToggleSpeakerPageVideoPopup() {
      this.showSpeakerPageVideoPopup = !this.showSpeakerPageVideoPopup;
    },
    closeSpeakerPageVideoPopup() {
      this.showSpeakerPageVideoPopup = false;
    },

    handleEnvLog() {
      if (process.env.VUE_APP_ENV_LOG === "true") {
        // console.log("============= Env =============");
        // console.log(process.env);
        const sessionToken = this.$route.query?.sessionToken;
        if (sessionToken) {
          localStorage.setItem("sessionToken", sessionToken);
        } else {
          localStorage.removeItem("sessionToken");
        }
      }
    },

    toggleMenu() {
      this.showMobMenu = true;
    },
    closeMobMenu() {
      this.showMobMenu = false;
    },
    toggleCurrentLanguage() {
      this.siteCurrentLangGe = !this.siteCurrentLangGe;
    },
    setCurrentLanguage(v) {
      // console.log(v);
      if (v == "true") {
        this.siteCurrentLangGe = true;
      } else {
        this.siteCurrentLangGe = false;
      }
      this.showMobMenu = false;
    },
    checkWindowWidth() {
      var width = document.body.clientWidth;
      if (width >= 1201) {
        this.isDescWidth = true;
        this.showMobMenu = false;
      } else {
        this.isDescWidth = false;
      }
      if (width >= 769 && width <= 1200) {
        this.isTabWidth = true;
      } else {
        this.isTabWidth = false;
      }
      if (width >= 600 && width <= 768) {
        this.isMobWidth = true;
      } else {
        this.isMobWidth = false;
      }
      if (width < 600) {
        this.isXSWidth = true;
      } else {
        this.isXSWidth = false;
      }
    },
    scrollToID(id) {
      var VueScrollTo = require("vue-scrollto");
      var options = {
        container: ".main-wrapper",
        easing: "ease-in",
        lazy: false,
        offset: -120,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      // console.log("id", id, options);
      var cancelScroll1 = VueScrollTo.scrollTo(id, 100, options);
      this.showMobMenu = false;
    },

    backToTopNav() {
      this.showMobMenu = false;
    },
  },
  watch: {},
  computed: {
    isMobile() {
      return CoreService.isMobile();
    },
    generateText() {
      switch (this.siteCurrentLangGe) {
        case true:
          return this.langtext["ka"];

        case false:
          return this.langtext["en"];

        default:
          return this.langtext["ka"];
      }
    },
    navbarObj() {
      return {
        showMobMenu: this.showMobMenu,
        toggleMenu: this.toggleMenu,
        closeMobMenu: this.closeMobMenu,
        backToTopNav: this.backToTopNav,
        scrollToID: this.scrollToID,
        siteCurrentLangGe: this.siteCurrentLangGe,
        setCurrentLanguage: this.setCurrentLanguage,
        toggleCurrentLanguage: this.toggleCurrentLanguage,
        ticketsSaleStart: this.ticketsSaleStart,
        openAccreditationPopup: this.openAccreditationPopup,
        openTicketsPopup: this.openTicketsPopup,
        openAboutUsPopup: this.openAboutUsPopup,
        generateText: this.generateText,
        showMomentsGallery: this.showMomentsGallery,
        closeMomentsGallery: this.closeMomentsGallery,
        showSpeakerVideoPopup: this.showSpeakerVideoPopup,
        closeSpeakerVideoPopup: this.closeSpeakerVideoPopup,
        showSpeakerPageVideoPopup: this.showSpeakerPageVideoPopup,
        closeSpeakerPageVideoPopup: this.closeSpeakerPageVideoPopup,
      };
    },
    homeObj() {
      return {
        siteCurrentLangGe: this.siteCurrentLangGe,
        isMobWidth: this.isMobWidth,
        isXSWidth: this.isXSWidth,
        generateText: this.generateText,
        FlashbacksDataFirstDay: this.FlashbacksDataFirstDay,
        FlashbacksDataSecondDay: this.FlashbacksDataSecondDay,
        speakers: this.speakers,
        showMomentsGallery: this.showMomentsGallery,
        openMomentsGallery: this.openMomentsGallery,
        closeMomentsGallery: this.closeMomentsGallery,
        showSpeakerVideoPopup: this.showSpeakerVideoPopup,
        openSpeakerVideoPopup: this.openSpeakerVideoPopup,
        closeSpeakerVideoPopup: this.closeSpeakerVideoPopup,
        ToggleSpeakerVideoPopup: this.ToggleSpeakerVideoPopup,
        showSpeakerPageVideoPopup: this.showSpeakerPageVideoPopup,
        openSpeakerPageVideoPopup: this.openSpeakerPageVideoPopup,
        closeSpeakerPageVideoPopup: this.openSpeakerPageVideoPopup,
        ToggleSpeakerPageVideoPopup: this.ToggleSpeakerPageVideoPopup,
        speakersShowcase: this.speakersShowcase,
        scrollToID: this.scrollToID,
      };
    },
    footerObj() {
      return {
        generateText: this.generateText,
      };
    },
    speakers() {
      return [
        {
          id: 1,
          name: this.generateText.ClarenceSeedorf,
          photo: "Seedorf.png",
          pagePhoto: "speaker-ClarenceSeedorf.png",
          tooltipDescription: this.generateText.ClarenceSeedorfTD,
          description: this.generateText.ClarenceSeedorfD,
          shortDescription: this.generateText.ClarenceSeedorfSD,
          company: this.generateText.ClarenceSeedorfC,
          position: this.generateText.ClarenceSeedorfP,
          active: true,
          color: "red-top",
          images: "speaker-ClarenceSeedorf-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/di3SEmw86cE?si=yYkSlE8bM42YuJ6Z",
          hasVideo: true,
        },
        {
          id: 2,
          name: this.generateText.PatrickKluivert,
          photo: "Patrick Kluivert.png",
          pagePhoto: "speaker-PatrickKluivert.png",
          tooltipDescription: this.generateText.PatrickKluivertTD,
          description: this.generateText.PatrickKluivertD,
          shortDescription: this.generateText.PatrickKluivertSD,
          company: this.generateText.PatrickKluivertC,
          position: this.generateText.PatrickKluivertP,
          active: true,
          color: "orange-bottom",
          images: "speaker-PatrickKluivert-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/EYJi7_jZr8E?si=qf86VEI-zLNVb5ED",
          hasVideo: true,
        },
        {
          id: 3,
          name: this.generateText.ShotaArveladze,
          photo: "Shota Arveladze.png",
          pagePhoto: "speaker-ShotaArveladze.png",
          tooltipDescription: this.generateText.ShotaArveladzeTD,
          description: this.generateText.ShotaArveladzeD,
          shortDescription: this.generateText.ShotaArveladzeSD,
          company: this.generateText.ShotaArveladzeC,
          position: this.generateText.ShotaArveladzeP,
          active: true,
          color: "green-top",
          images: "speaker-ShotaArveladze-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/u-7HjzTe5Qk?si=adTRry_s21id_h45",
          hasVideo: true,
        },
        {
          id: 4,
          name: this.generateText.TemurKetsbaia,
          photo: "Temur Ketsbaia.png",
          pagePhoto: "speaker-TemurKetsbaia.png",
          tooltipDescription: this.generateText.TemurKetsbaiaTD,
          description: this.generateText.TemurKetsbaiaD,
          shortDescription: this.generateText.TemurKetsbaiaSD,
          company: this.generateText.TemurKetsbaiaC,
          position: this.generateText.TemurKetsbaiaP,
          active: true,
          color: "violet-bottom",
          images: "speaker-TemurKetsbaia-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/u-7HjzTe5Qk?si=adTRry_s21id_h45",
          hasVideo: true,
        },
        {
          id: 5,
          name: this.generateText.RachelRamsey,
          photo: "Rachel Ramsay.png",
          pagePhoto: "speaker-RachelRamsey.png",
          tooltipDescription: this.generateText.RachelRamseyTD,
          description: this.generateText.RachelRamseyD,
          shortDescription: this.generateText.RachelRamseySD,
          company: this.generateText.RachelRamseyC,
          position: this.generateText.RachelRamseyP,
          active: false,
          color: "blue-top",
          images: "speaker-RachelRamsey-imgs.png",
          videoLink: "",
          hasVideo: false,
        },
        {
          id: 6,
          name: this.generateText.MaryPilon,
          photo: "Mary Pilon.png",
          pagePhoto: "speaker-MaryPilon.png",
          tooltipDescription: this.generateText.MaryPilonTD,
          description: this.generateText.MaryPilonD,
          shortDescription: this.generateText.MaryPilonSD,
          company: this.generateText.MaryPilonC,
          position: this.generateText.MaryPilonP,
          active: false,
          color: "red-bottom",
          images: "speaker-MaryPilon-imgs.png",
          videoLink: "https://www.youtube.com/embed/WR2JpOgZGrU",
          hasVideo: true,
        },
        {
          id: 7,
          name: this.generateText.JodyAvirgan,
          photo: "Jody Avirgan.png",
          pagePhoto: "speaker-JodyAvirgan.png",
          tooltipDescription: this.generateText.JodyAvirganTD,
          description: this.generateText.JodyAvirganD,
          shortDescription: this.generateText.JodyAvirganSD,
          company: this.generateText.JodyAvirganC,
          position: this.generateText.JodyAvirganP,
          active: false,
          color: "orange-top",
          images: "speaker-JodyAvirgan-imgs.png",
          videoLink: "",
          hasVideo: false,
        },
        {
          id: 8,
          name: this.generateText.AdamNeuhaus,
          photo: "Adam Neuhaus.png",
          pagePhoto: "speaker-AdamNeuhaus.png",
          tooltipDescription: this.generateText.AdamNeuhausTD,
          description: this.generateText.AdamNeuhausD,
          shortDescription: this.generateText.AdamNeuhausSD,
          company: this.generateText.AdamNeuhausC,
          position: this.generateText.AdamNeuhausP,
          active: false,
          color: "green-bottom",
          images: "speaker-AdamNeuhaus-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/EYJi7_jZr8E?si=qf86VEI-zLNVb5ED",
          hasVideo: true,
        },
        {
          id: 9,
          name: this.generateText.MatthewJanney,
          photo: "Matthew Janney.png",
          pagePhoto: "speaker-MatthewJanney.png",
          tooltipDescription: this.generateText.MatthewJanneyTD,
          description: this.generateText.MatthewJanneyD,
          shortDescription: this.generateText.MatthewJanneySD,
          company: this.generateText.MatthewJanneyC,
          position: this.generateText.MatthewJanneyP,
          active: false,
          color: "violet-top ",
          images: "speaker-MatthewJanney-imgs.png",
          videoLink: "https://www.youtube.com/embed/OvS5SeycdMA",
          hasVideo: true,
        },

        {
          id: 10,
          name: this.generateText.ErekleInashvili,
          photo: "Erekle Inashvili.png",
          pagePhoto: "speaker-ErekleInashvili.png",
          tooltipDescription: this.generateText.ErekleInashviliTD,
          description: this.generateText.ErekleInashviliD,
          shortDescription: this.generateText.ErekleInashviliSD,
          company: this.generateText.ErekleInashviliC,
          position: this.generateText.ErekleInashviliP,
          active: false,
          color: "blue-bottom",
          images: "speaker-ErekleInashvili-imgs.png",
          videoLink: "",
          hasVideo: false,
        },
        {
          id: 11,
          name: this.generateText.GogiGvakharia,
          photo: "Gogi Gvakharia.png",
          pagePhoto: "speaker-GogiGvakharia.png",
          tooltipDescription: this.generateText.GogiGvakhariaTD,
          description: this.generateText.GogiGvakhariaD,
          shortDescription: this.generateText.GogiGvakhariaSD,
          company: this.generateText.GogiGvakhariaC,
          position: this.generateText.GogiGvakhariaP,
          active: true,
          color: "red-top",
          images: "speaker-GogiGvakharia-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/MsrqdmFOorQ?si=kAtCrRJV1i75z3wo",
          hasVideo: true,
        },
        {
          id: 12,
          name: this.generateText.GeorgeKipiani,
          photo: "George Kipiani.png",
          pagePhoto: "speaker-GeorgeKipiani.png",
          tooltipDescription: this.generateText.GeorgeKipianiTD,
          description: this.generateText.GeorgeKipianiD,
          shortDescription: this.generateText.GeorgeKipianiSD,
          company: this.generateText.GeorgeKipianiC,
          position: this.generateText.GeorgeKipianiP,
          active: true,
          color: "orange-bottom",
          images: "speaker-GeorgeKipiani-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/MsrqdmFOorQ?si=kAtCrRJV1i75z3wo",
          hasVideo: true,
        },
        {
          id: 13,
          name: this.generateText.NikaAmashukeli,
          photo: "Nika Amashukeli.png",
          pagePhoto: "speaker-NikaAmashukeli.png",
          tooltipDescription: this.generateText.NikaAmashukeliTD,
          description: this.generateText.NikaAmashukeliD,
          shortDescription: this.generateText.NikaAmashukeliSD,
          company: this.generateText.NikaAmashukeliC,
          position: this.generateText.NikaAmashukeliP,
          active: true,
          color: "green-top",
          images: "speaker-NikaAmashukeli-imgs.png",
          videoLink: "https://www.youtube.com/embed/OvS5SeycdMA",
          hasVideo: true,
        },
        {
          id: 14,
          name: this.generateText.Dudey,
          photo: "Dudey.png",
          pagePhoto: "speaker-Dudey.png",
          tooltipDescription: this.generateText.DudeyTD,
          description: this.generateText.DudeyD,
          shortDescription: this.generateText.DudeySD,
          company: this.generateText.DudeyC,
          position: this.generateText.DudeyP,
          active: true,
          color: "violet-bottom",
          images: "speaker-Dudey-imgs.png",
          videoLink:
            "https://www.youtube.com/embed/zRgFViXgPZ4?si=S0IWQL3rsv9d7Lov",
          hasVideo: true,
        },
        {
          id: 15,
          name: this.generateText.LeonorRothes,
          photo: "Leonor Rothes.png",
          pagePhoto: "speaker-LeonorRothes.png",
          tooltipDescription: this.generateText.LeonorRothesTD,
          description: this.generateText.LeonorRothesD,
          shortDescription: this.generateText.LeonorRothesSD,
          company: this.generateText.LeonorRothesC,
          position: this.generateText.LeonorRothesP,
          active: true,
          color: "blue-top",
          images: "speaker-LeonorRothes-imgs.png",
          videoLink: "",
          hasVideo: false,
        },
        {
          id: 16,
          name: this.generateText.DachiImedadze,
          photo: "Dachi Imedadze.png",
          pagePhoto: "speaker-DachiImedadze.png",
          tooltipDescription: this.generateText.DachiImedadzeTD,
          description: this.generateText.DachiImedadzeD,
          shortDescription: this.generateText.DachiImedadzeSD,
          company: this.generateText.DachiImedadzeC,
          position: this.generateText.DachiImedadzeP,
          active: true,
          color: "red-bottom",
          images: "speaker-DachiImedadze-imgs.png",
          videoLink: "",
          hasVideo: false,
        },
        {
          id: 17,
          name: this.generateText.NinoGordeladze,
          photo: "Nino Gordeladze.png",
          pagePhoto: "speaker-NinoGordeladze.png",
          tooltipDescription: this.generateText.NinoGordeladzeTD,
          description: this.generateText.NinoGordeladzeD,
          shortDescription: this.generateText.NinoGordeladzeSD,
          company: this.generateText.NinoGordeladzeC,
          position: this.generateText.NinoGordeladzeP,
          active: false,
          color: "orange-top",
          images: "speaker-NinoGordeladze-imgs.png",
          videoLink: "",
          hasVideo: false,
        },
      ];
    },
    speakersShowcase() {
      return [
        {
          id: 1,
          head: this.generateText.SpeakerShowcaseHead1,
          imageUrl: "ShotaArveladze-TemurKetsbaia.webp",
          videoUrl:
            "https://www.youtube.com/embed/u-7HjzTe5Qk?si=adTRry_s21id_h45",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName1,
        },
        {
          id: 2,
          head: this.generateText.SpeakerShowcaseHead2,
          imageUrl: "ShotaArveladze-Seedorf.webp",
          videoUrl:
            "https://www.youtube.com/embed/di3SEmw86cE?si=yYkSlE8bM42YuJ6Z",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName2,
        },
        {
          id: 3,
          head: this.generateText.SpeakerShowcaseHead3,
          imageUrl: "Dudey.webp",
          videoUrl:
            "https://www.youtube.com/embed/zRgFViXgPZ4?si=S0IWQL3rsv9d7Lov",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName3,
        },
        {
          id: 4,
          head: this.generateText.SpeakerShowcaseHead4,
          imageUrl: "GeorgeKipiani.webp",
          videoUrl:
            "https://www.youtube.com/embed/MsrqdmFOorQ?si=kAtCrRJV1i75z3wo",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName4,
        },
        {
          id: 5,
          head: this.generateText.SpeakerShowcaseHead5,
          imageUrl: "MatthewJanney-NikaAmashukeli.webp",
          videoUrl: "https://www.youtube.com/embed/OvS5SeycdMA",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName5,
        },
        {
          id: 6,
          head: this.generateText.SpeakerShowcaseHead10,
          imageUrl: "MaryPilon.webp",
          videoUrl: "https://www.youtube.com/embed/WR2JpOgZGrU",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName10,
        },
        {
          id: 11,
          head: this.generateText.SpeakerShowcaseHead11,
          imageUrl: "AdamNeuhaus-PatrickKluivert.webp",
          videoUrl:
            "https://www.youtube.com/embed/EYJi7_jZr8E?si=qf86VEI-zLNVb5ED",
          hasVideo: true,
          name: this.generateText.SpeakerShowcaseName11,
        },
        {
          id: 7,
          head: this.generateText.SpeakerShowcaseHead7,
          imageUrl: "ErekleInashvili.webp",
          videoUrl: "",
          hasVideo: false,
          name: this.generateText.SpeakerShowcaseName7,
        },
        {
          id: 8,
          head: this.generateText.SpeakerShowcaseHead8,
          imageUrl: "RachelRamsay.webp",
          videoUrl: "",
          hasVideo: false,
          name: this.generateText.SpeakerShowcaseName8,
        },
        {
          id: 9,
          head: this.generateText.SpeakerShowcaseHead9,
          imageUrl: "JodyAvirgan.webp",
          videoUrl: "",
          hasVideo: false,
          name: this.generateText.SpeakerShowcaseName9,
        },
        {
          id: 10,
          head: this.generateText.SpeakerShowcaseHead6,
          imageUrl: "AdamNeuhaus.webp",
          videoUrl: "",
          hasVideo: false,
          name: this.generateText.SpeakerShowcaseName6,
        },
      ];
    },

    FlashbacksDataFirstDay() {
      return [
        {
          id: 1,
          title: this.siteCurrentLangGe
            ? "რეგისტრაცია"
            : "Doors Open and Registration",
          desc: this.siteCurrentLangGe
            ? "სტუმრებს საჩუქრებით სავსე ჩანთები გადავეცით და სიურპრიზები დავახვედრეთ."
            : "Guests arrived and received goodie bags filled with surprises.",
          img: "first-day-1.webp",
        },
        {
          id: 2,
          title: this.siteCurrentLangGe ? "მოდერატორები" : "Moderators",
          desc: this.siteCurrentLangGe
            ? "დაჩიმ და ლეონორმა სფიქერები და ფორუმის საკითხები სტუმრებს გააცნეს."
            : "Our fantastic moderators, Dachi and Leonor, introduced new speakers and topics to our wonderful attendees.",
          img: "first-day-2.webp",
        },
        {
          id: 3,
          title: this.siteCurrentLangGe
            ? "გახსნის ცერემონია"
            : "Opening Ceremony",
          desc: this.siteCurrentLangGe
            ? "დიმიტრი ვაჩნაძემ ევროპოპის აპლიკაცია წარადგინა."
            : "Dimitri Vachnadze launched the europop app.",
          img: "first-day-3.webp",
        },
        {
          id: 4,
          title: this.siteCurrentLangGe
            ? "პანელური დისკუსია: საქართველო EURO 2024-ზე"
            : "Panel Discussion: Georgia on EURO 2024",
          desc: this.siteCurrentLangGe
            ? "შოთა არველაძემ და თემურ ქეცბაიამ საქართველოს Euro 2024-ზე მონაწილეობაზე ისაუბრეს."
            : "Shota Arveladze and Temur Ketsbaia discussed Georgia's participation in Euro 2024.",
          img: "first-day-4.webp",
        },
        {
          id: 5,
          title: this.siteCurrentLangGe
            ? "ფილმის ჩვენება: ლეგენდების სერია 001 - კარს მიღმა"
            : "Movie Screening: Legend Series 001 - Backstage",
          desc: this.siteCurrentLangGe
            ? "ნინო გორდელაძის შეხვედრა მილანის ინტერის ხელმძღვანელობასთან."
            : "Nino Gordeladze meeting Inter Milan management was showcased.",
          img: "first-day-5.webp",
        },
        {
          id: 6,
          title: this.siteCurrentLangGe
            ? "მთავარი მოვლენა: ლეგენდები სცენაზე"
            : "Main Event: Legends Talking",
          desc: this.siteCurrentLangGe
            ? "შოთა არველაძემ და კლარენს სედორფმა ძალიან საინტერესო საკითხები განიხილეს."
            : "Shota Arveladze and Clarence Seedorf engaged in an insightful conversation.",
          img: "first-day-6.webp",
        },
        {
          id: 7,
          title: this.siteCurrentLangGe ? "გასართობი ზონა" : "Game Zone",
          desc: this.siteCurrentLangGe
            ? "სტუმრებს გასართობ ზონაში რეტრო კონსოლები და ყველასთვის საყვარელი თამაშები დავახვედრეთ."
            : "The Game Zone was a hit with vintage and nostalgic video games!",
          img: "first-day-7.webp",
        },
        {
          id: 8,
          title: this.siteCurrentLangGe ? "ნეთვორქინგი" : "Networking Drinks",
          desc: this.siteCurrentLangGe
            ? "დღის ბოლოს სტუმრებს სასმელით გავუმასპინძლდით და ნეთვორქინგის საშუალება მივეცით."
            : "The day ended with networking drinks, allowing attendees to connect.",
          img: "first-day-8.webp",
        },
      ];
    },
    FlashbacksDataSecondDay() {
      return [
        {
          id: 9,
          title: this.siteCurrentLangGe ? "ნეთვორქინგი" : "Morning Networking ",
          desc: this.siteCurrentLangGe
            ? "დილა ნეთვორქინგითა და საინტერესო იდეების გაცვლით დაიწყო."
            : "The day started with networking and idea exchanges.",
          img: "second-day-1.webp",
        },
        {
          id: 10,
          title: this.siteCurrentLangGe
            ? "პრეზენტაცია: გოგო, რომელსაც MMA შეუყვარდა."
            : "Keynote: The Oceanic Odyssey",
          desc: this.siteCurrentLangGe
            ? "დუდიმ მოგვიყვა თუ როგორ აღმოაჩინა და შეიყვარა MMA."
            : "Dudey shared her journey of falling in love with MMA.",
          img: "second-day-2.webp",
        },
        {
          id: 11,
          title: this.siteCurrentLangGe
            ? "პანელური დისკუსია: რაგბის რეალობა"
            : "Panel Discussion: Rugby Realities",
          desc: this.siteCurrentLangGe
            ? "მეთიუ ჯენიმ და ნიკა ამაშუკელმა თანამედროვე რაგბის მოტივაციებსა და გამოწვევებზე ისაუბრეს."
            : "Matthew Janney and Nika Amashukeli engaged in a motivational conversation full of energy.",
          img: "second-day-3.webp",
        },
        {
          id: 12,
          title: this.siteCurrentLangGe
            ? "პრეზენტაცია: სპორტზე თხრობის ხელოვნება"
            : "Keynote: The Art of Sports Storytelling",
          desc: this.siteCurrentLangGe
            ? "ადამ ნეუჰაუსმა სპორტზე თხრობის ნიუანსები გაგვიზიარა."
            : "Adam Neuhaus spoke about the nuances of sports storytelling.",
          img: "second-day-4.webp",
        },
        {
          id: 13,
          title: this.siteCurrentLangGe
            ? "პრეზენტაცია: ბრენდინგის მნიშვნელობა თანამედროვე ფეხბურთში"
            : "Keynote: Power of Branding in Modern Football",
          desc: this.siteCurrentLangGe
            ? "ერეკლე ინაშვილმა ფეხბურთში წარმატებული ბრენდინგის მაგალითები შემოგვთავაზა."
            : "Erekle Inashvili presented an engaging session on football branding.",
          img: "second-day-5.webp",
        },
        {
          id: 14,
          title: this.siteCurrentLangGe
            ? "პრეზენტაცია: სპორტით მოყოლილი ამბები"
            : "Keynote: The Power of Sport as a Narrative Tool",
          desc: this.siteCurrentLangGe
            ? "რეიჩელ რემზიმ ამბის თხრობაში სპორტის მნიშვნელობაზე გვიამბო."
            : "Rachel Ramsay discussed using sport as a powerful narrative medium.",
          img: "second-day-6.webp",
        },
        {
          id: 15,
          title: this.siteCurrentLangGe
            ? "ფილმის ჩვენება: შვილი"
            : "Live Performance: Shvili ('The Son')",
          desc: this.siteCurrentLangGe
            ? "გოგი გვახარიამ სცენაზე გიორგი ყიფიანი მიიღო."
            : "Gogi Gvakharia hosted George Kipiani for a live stage performance.",
          img: "second-day-7.webp",
        },
        {
          id: 16,
          title: this.siteCurrentLangGe ? "ნეთვორქინგი" : "Networking Break",
          desc: this.siteCurrentLangGe
            ? "შესვენება ნეთვორქინგისა და გამაჯანსაღებელი სასმელებისთვის."
            : "A break for networking and refreshments.",
          img: "second-day-8.webp",
        },
        {
          id: 17,
          title: this.siteCurrentLangGe
            ? "პრეზენტაცია: პოდკასტის ძალა"
            : "Keynote: The Power of Podcast",
          desc: this.siteCurrentLangGe
            ? "ჯოდი ავირგანი თანამედროვე სამყაროში პოდკასტების მნიშვნელობაზე გვესაუბრა."
            : "Jody Avirgan discussed the impact and significance of podcasts today.",
          img: "second-day-9.webp",
        },
        {
          id: 18,
          title: this.siteCurrentLangGe
            ? "Live Performance: შოთა არველაძე"
            : "Live Performance: Shota Arveladze",
          desc: this.siteCurrentLangGe
            ? "შოთა არველაძის უნიკალური გამოსვლა."
            : "Shota Arveladze performed a unique live act.",
          img: "second-day-10.webp",
        },
        {
          id: 19,
          title: this.siteCurrentLangGe
            ? "პრეზენტაცია: მარცხი - რაზე საუბარიც არ გვიყვარს"
            : "Keynote: LOSERS - Sport's Great Taboos",
          desc: this.siteCurrentLangGe
            ? "მერი პილოუნი პრეზენტაციაში სპორტის ერთ-ერთ უდიდეს ტაბუს შეეხო."
            : "Mary Pilon addressed one of sport's significant taboos in her keynote.",
          img: "second-day-11.webp",
        },
        {
          id: 20,
          title: this.siteCurrentLangGe
            ? "დისკუსია: თავდასხმის ხელოვნება"
            : "In-Conversation: The Art of Striking",
          desc: this.siteCurrentLangGe
            ? "ადამ ნეუჰაუსმა და პატრიკ კლუივერტმა თავდასხმის ტექნიკებზე ისაუბრეს."
            : "Adam Neuhaus and Patrick Kluivert discussed striking techniques.",
          img: "second-day-12.webp",
        },
        {
          id: 21,
          title: this.siteCurrentLangGe ? "Afterparty" : "Afterparty",
          desc: this.siteCurrentLangGe
            ? "დღის ბოლოს ჩვენს დაუვიწყარ წვეულებას MTKVARZE-მ უმასპინძლა."
            : "The day concluded with an unforgettable afterparty featuring Young Georgian Lolitaz, MokuMoku and Moku J & Moku T at MTKVARZE.",
          img: "second-day-13.webp",
        },
      ];
    },
  },
  created() {
    this.handleEnvLog();
  },
  beforeMount() {},
  mounted() {
    PostMessageService.LoadingFinished();
    window.addEventListener("resize", this.checkWindowWidth);
    this.checkWindowWidth();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";

@import "@/assets/scss/eb-mixin.scss";
// reset styles
body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
abbr {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ul li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
legend {
  color: #000;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}

// main styles

html {
  background-color: #fff !important;
  height: 100%;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  font-family: "Helvetica-Roman";
  font-size: 16px;
  line-height: 1.2 !important;
  background-color: #d9e0f3 !important;
  color: #000000;
  margin: 0 auto !important;
  scroll-behavior: smooth;
}
#app {
  height: 100%;
}

.wrapper {
  width: 100%;
  max-width: 1700px;
  min-width: 320px;
  margin: 0 auto;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  @media only screen and (max-width: 1366px) {
    max-width: 1100px;
    &.full {
      max-width: 100%;
    }
  }
}
.content {
  width: 100%;
  // min-height: 100%;
  min-height: calc(100% - 104px);
  display: flex;
  background: rgba(217, 224, 243, 1);
  background: -moz-linear-gradient(
    top,
    rgba(217, 224, 243, 1) 0%,
    rgba(200, 187, 253, 1) 26%,
    rgba(200, 187, 253, 1) 27%,
    rgba(224, 226, 227, 1) 54%,
    rgba(233, 243, 213, 1) 72%,
    rgba(247, 145, 49, 1) 81%,
    rgba(247, 145, 49, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(217, 224, 243, 1)),
    color-stop(26%, rgba(200, 187, 253, 1)),
    color-stop(27%, rgba(200, 187, 253, 1)),
    color-stop(54%, rgba(224, 226, 227, 1)),
    color-stop(72%, rgba(233, 243, 213, 1)),
    color-stop(81%, rgba(247, 145, 49, 1)),
    color-stop(100%, rgba(247, 145, 49, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(217, 224, 243, 1) 0%,
    rgba(200, 187, 253, 1) 26%,
    rgba(200, 187, 253, 1) 27%,
    rgba(224, 226, 227, 1) 54%,
    rgba(233, 243, 213, 1) 72%,
    rgba(247, 145, 49, 1) 81%,
    rgba(247, 145, 49, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(217, 224, 243, 1) 0%,
    rgba(200, 187, 253, 1) 26%,
    rgba(200, 187, 253, 1) 27%,
    rgba(224, 226, 227, 1) 54%,
    rgba(233, 243, 213, 1) 72%,
    rgba(247, 145, 49, 1) 81%,
    rgba(247, 145, 49, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(217, 224, 243, 1) 0%,
    rgba(200, 187, 253, 1) 26%,
    rgba(200, 187, 253, 1) 27%,
    rgba(224, 226, 227, 1) 54%,
    rgba(233, 243, 213, 1) 72%,
    rgba(247, 145, 49, 1) 81%,
    rgba(247, 145, 49, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(217, 224, 243, 1) 0%,
    rgba(200, 187, 253, 1) 26%,
    rgba(200, 187, 253, 1) 27%,
    rgba(224, 226, 227, 1) 54%,
    rgba(233, 243, 213, 1) 72%,
    rgba(247, 145, 49, 1) 81%,
    rgba(247, 145, 49, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9e0f3', endColorstr='#eabb8e', GradientType=0 );

  &.blur {
    filter: blur(2px);
  }
  @media only screen and (max-width: 600px) {
    background: -moz-linear-gradient(
      top,
      rgba(217, 224, 243, 1) 0%,
      rgba(200, 187, 253, 1) 26%,
      rgba(200, 187, 253, 1) 27%,
      rgba(224, 226, 227, 1) 54%,
      rgba(233, 243, 213, 1) 72%,
      rgba(247, 145, 49, 1) 87%,
      rgba(247, 145, 49, 1) 99%,
      rgba(247, 145, 49, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(217, 224, 243, 1)),
      color-stop(26%, rgba(200, 187, 253, 1)),
      color-stop(27%, rgba(200, 187, 253, 1)),
      color-stop(54%, rgba(224, 226, 227, 1)),
      color-stop(72%, rgba(233, 243, 213, 1)),
      color-stop(87%, rgba(247, 145, 49, 1)),
      color-stop(99%, rgba(247, 145, 49, 1)),
      color-stop(100%, rgba(247, 145, 49, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(217, 224, 243, 1) 0%,
      rgba(200, 187, 253, 1) 26%,
      rgba(200, 187, 253, 1) 27%,
      rgba(224, 226, 227, 1) 54%,
      rgba(233, 243, 213, 1) 72%,
      rgba(247, 145, 49, 1) 87%,
      rgba(247, 145, 49, 1) 99%,
      rgba(247, 145, 49, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(217, 224, 243, 1) 0%,
      rgba(200, 187, 253, 1) 26%,
      rgba(200, 187, 253, 1) 27%,
      rgba(224, 226, 227, 1) 54%,
      rgba(233, 243, 213, 1) 72%,
      rgba(247, 145, 49, 1) 87%,
      rgba(247, 145, 49, 1) 99%,
      rgba(247, 145, 49, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(217, 224, 243, 1) 0%,
      rgba(200, 187, 253, 1) 26%,
      rgba(200, 187, 253, 1) 27%,
      rgba(224, 226, 227, 1) 54%,
      rgba(233, 243, 213, 1) 72%,
      rgba(247, 145, 49, 1) 87%,
      rgba(247, 145, 49, 1) 99%,
      rgba(247, 145, 49, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(217, 224, 243, 1) 0%,
      rgba(200, 187, 253, 1) 26%,
      rgba(200, 187, 253, 1) 27%,
      rgba(224, 226, 227, 1) 54%,
      rgba(233, 243, 213, 1) 72%,
      rgba(247, 145, 49, 1) 87%,
      rgba(247, 145, 49, 1) 99%,
      rgba(247, 145, 49, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9e0f3', endColorstr='#DCE4ED', GradientType=0 );
  }
}
.main-wrapper {
  width: 100%;
  overflow: hidden;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  position: relative;
  height: 100%;
  top: 0;
  overflow-y: scroll;

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }

  scrollbar-width: thin;
  scroll-behavior: smooth;

  .moments-gallery-main {
    background: transparent;
    z-index: 9999;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: calc(100%);
    margin: 0 auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      top: 53px;
      height: calc(100% - 53px);
      z-index: 99;
    }
    .close-btn {
      position: absolute;
      width: 24px;
      height: 24px;
      // right: 44px;
      // top: 44px;
      right: 2.5%;
      top: 5.5%;
      color: #000;
      cursor: pointer;
      background: url("./assets/home-component/close-btn.png") no-repeat center;
      background-size: contain;
      z-index: 99;
      @media only screen and (max-width: 1360px) {
        // display: none;
        z-index: 99;
        top: 10px;
        right: 32px;
        width: 24px;
        height: 24px;
      }
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .speaker-video-main {
    background: transparent;
    z-index: 9999;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: calc(100%);
    margin: 0 auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      // background: #fff;
      top: 53px;
      height: calc(100% - 53px);
      z-index: 99;
    }
    .close-btn {
      position: absolute;
      width: 32px;
      height: 32px;
      // right: 44px;
      // top: 44px;
      right: 2.5%;
      top: 5.5%;
      color: #000;
      cursor: pointer;
      background: url("./assets/home-component/close-btn.png") no-repeat center;
      background-size: contain;
      z-index: 99;
      @media only screen and (max-width: 1360px) {
        // display: none;
        z-index: 99;
        top: 10px;
        right: 32px;
        width: 24px;
        height: 24px;
      }
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .speaker-video-container {
      background-position: center;
      border-radius: 8px;
      padding: 44px 0px 32px;
      // background: #f1f3f8;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.8);
      // filter: blur(20px);
      backdrop-filter: blur(10px);
      color: #000;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      position: relative;
      overflow: unset;
      overflow-y: unset;
      scroll-behavior: smooth;
      display: flex;
      justify-content: center;
      align-items: center;
      @-moz-document url-prefix() {
        @media only screen and (max-width: 768px) {
          scrollbar-color: rgba(0, 0, 0, 0.3) #dce4ed;
          scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
        }
      }

      .speaker-video-box {
        position: relative;
        width: 100%;
        max-width: 1024px;
        margin: auto;
        padding-bottom: 30.2%;
        @include interpolate(height, 375px, 1920px, 210px, 578px);
        border-radius: 5px;
      }
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        outline: none;
        border: none;
        border-radius: 5px;
      }
      @media only screen and (max-width: 768px) {
        border-top: 1px solid rgba(155, 169, 208, 0.4);
        padding: 16px 0px;
        background: linear-gradient(180deg, #d9e0f3 0%, #f2f4fb 100%);
        width: 100%;
        height: 100%;
        border-radius: 0px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::scrollbar-track {
          border-radius: 10px;
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 5px !important;
        }
        .speaker-video-box {
          border-radius: 0;
        }
        iframe {
          border-radius: 0;
        }
      }
    }
  }
  .speaker-page-video-main {
    background: transparent;
    z-index: 9999;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: calc(100%);
    margin: 0 auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      // background: #fff;
      top: 53px;
      height: calc(100% - 53px);
      z-index: 99;
    }
    .close-btn {
      position: absolute;
      width: 32px;
      height: 32px;
      // right: 44px;
      // top: 44px;
      right: 2.5%;
      top: 5.5%;
      color: #000;
      cursor: pointer;
      background: url("./assets/home-component/close-btn.png") no-repeat center;
      background-size: contain;
      z-index: 99;
      @media only screen and (max-width: 1360px) {
        // display: none;
        z-index: 99;
        top: 10px;
        right: 32px;
        width: 24px;
        height: 24px;
      }
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .speaker-page-video-container {
      background-position: center;
      border-radius: 8px;
      padding: 44px 0px 32px;
      // background: #f1f3f8;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.8);
      // filter: blur(20px);
      backdrop-filter: blur(10px);
      color: #000;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      position: relative;
      overflow: unset;
      overflow-y: unset;
      scroll-behavior: smooth;
      display: flex;
      justify-content: center;
      align-items: center;
      @-moz-document url-prefix() {
        @media only screen and (max-width: 768px) {
          scrollbar-color: rgba(0, 0, 0, 0.3) #dce4ed;
          scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
        }
      }

      .speaker-page-video-box {
        position: relative;
        width: 100%;
        max-width: 1024px;
        margin: auto;
        padding-bottom: 30.2%;
        @include interpolate(height, 375px, 1920px, 210px, 578px);
        border-radius: 5px;
      }
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        outline: none;
        border: none;
        border-radius: 5px;
      }
      @media only screen and (max-width: 768px) {
        border-top: 1px solid rgba(155, 169, 208, 0.4);
        padding: 16px 0px;
        background: linear-gradient(180deg, #d9e0f3 0%, #f2f4fb 100%);
        width: 100%;
        height: 100%;
        border-radius: 0px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::scrollbar-track {
          border-radius: 10px;
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 5px !important;
        }
        .speaker-video-box {
          border-radius: 0;
        }
        iframe {
          border-radius: 0;
        }
      }
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}
.text-italic {
  font-style: italic;
}
.cursor-pointer {
  cursor: pointer;
}
.no-underline {
  text-decoration: none;
}
</style>
